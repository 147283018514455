<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">Program</th>
          <th class="text-left">Plan</th>
          <th class="text-left">Target Goal</th>
          <th class="text-left">Adjusted Target Goal</th>
        </tr>
      </thead>

      <tbody
        v-if="hasHistories"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
      >
        <tr
          class="clickable"
          v-for="(history, index) in histories"
          :key="index"
        >
          <td>{{ history.program.name }}</td>
          <td>
            <span v-if="history.program.package">
              {{ history.program.package.name }}
            </span>
          </td>
          <td>{{ history.target_goal | userGoal }}</td>
          <td>{{ history.adjusted_target_goal | userGoal }}</td>
        </tr>
      </tbody>

      <table-skeleton-loader v-if="loading" column="4" row />
    </v-simple-table>

    <no-list
      v-if="!hasHistories && !loading"
      class="my-14"
      details="No Available Goal History"
    />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'GoalHistoryList',

  directives: {
    infiniteScroll,
  },

  components: {
    NoList,
    TableSkeletonLoader,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    lists: {
      type: Array,
      required: true,
    },

    meta: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      histories: this.lists,
    }
  },

  computed: {
    hasHistories() {
      return !!this.lists.length
    },

    disableListScroll() {
      return this.loading || this.meta.current_page >= this.meta.last_page
    },
  },

  methods: {
    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },
  },

  watch: {
    lists(values) {
      this.histories = values
    },
  },
}
</script>
