<template>
  <div class="user-profile--goals">
    <v-row>
      <v-col cols="12" sm="12" md="10">
        <goal-history-list
          :lists="histories.list"
          :meta="histories.listMeta"
          :loading="loading"
          @loadMore="loadMore"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoalHistoryList from './components/GoalHistoryList'

export default {
  name: 'UserGoalHistory',

  components: {
    GoalHistoryList,
  },

  data() {
    return {
      loading: false,
      histories: {
        list: [],
        listMeta: {
          current_page: 0,
          last_page: 1,
        },
      },
    }
  },

  computed: {
    ...mapGetters({
      user: 'users/currentSelectedUserProfile',
      program: 'users/currentSelectedProgram',
    }),
  },

  created() {
    this.fetchUserGoalHistory()
  },

  methods: {
    ...mapActions({
      getGoalHistories: 'users/getGoalHistories',
    }),

    async fetchUserGoalHistory(page = 1) {
      if (!this.program.id || !this.user.id || this.loading) return

      this.loading = true

      let { data } = await this.getGoalHistories({
        userId: this.user.id,
        programId: this.program.id,
        page,
      })

      this.histories.list = data.data
      this.histories.listMeta = data.meta

      await this.$sleep()

      this.loading = false
    },

    loadMore() {
      if (
        this.histories.listMeta.current_page < this.histories.listMeta.last_page
      ) {
        this.fetchUserGoalHistory(this.listMeta.current_page + 1)
      }
    },
  },

  watch: {
    program(program) {
      this.fetchUserGoalHistory()
    },
  },
}
</script>
